<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Campaign Files"
    />
    <template v-if="matchbackError">
      <v-alert
        prominent
        type="error"
      >
        <v-row align="center">
          <v-col class="grow">
            <strong>There was a problem generating your matchbacks!</strong>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="grow">
            <p>Please contact Dealer Insights support for help.</p>
          </v-col>
        </v-row>
      </v-alert>
    </template>    
    <span
      class="font-weight-light subtitle-1"
    >
      <v-btn
        elevation="1"
        color="blue darken-1"
        dark
        @click="goBack"
      >
        <v-icon
          color="white"
        >
          mdi-keyboard-backspace
        </v-icon>
        Back
      </v-btn>
    </span>
    <template v-if="uploading">
      <v-progress-linear
        v-model="progress"
        color="light-blue"
        height="25"
        reactive
        striped
      >
        <strong>{{ progress }} %</strong>
      </v-progress-linear>
    </template>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Add Campaign Files
          </v-card-title>
          <template>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                v-model="description"
                :rules="[v => !!v || 'Description is required']"
                label="Description"
                required
              />
              <template>
                <v-file-input
                  show-size
                  label="Click to select files to upload."
                  multiple
                  @change="selectFile"
                />
              </template>
              <v-checkbox
                v-model="manifestFile"
              >
                <template v-slot:label>
                  This file is a manifest file.
                </template>
              </v-checkbox>
            </v-form>
          </template>
          <v-row dense>
            <v-col class="text-right">
              <v-btn
                color="primary"
                :disabled="(file === undefined || file == '' || uploading) && !valid"
                @click="handleFileUpload()"
              >
                Save and Upload
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="items.length > 0">
      <v-row dense>
        <v-col>
          <h2>
            {{ items.length }} Files
          </h2>
        </v-col>
      </v-row>
    </template>
    <v-row dense>
      <v-btn
        color="primary"
        :disabled="loading"
        @click="showMergeDialog = true"
      >
        Create Merge File
      </v-btn>
      <v-btn
        color="primary"
        :disabled="loading"
        @click="showMatchbackDialog=true"
      >
        Create Matchback Files
      </v-btn>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['name']"
          >
            <template #item.created_at="{value}">
              {{ formatIsoDate(value) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:item.url="{ item }">
              <a
                :href="item.url"
                target="_blank"
              >
                {{ item.url }}
              </a>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>    
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showMergeDialog"
      persistent
      max-width="500"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Create Merge File
        </v-card-title>
        <v-row dense>
          <v-col>
            <v-list shaped>
              <v-combobox
                v-model="selectedFiles"
                :items="dataFiles"
                item-text="description"
                item-value="id"
                label="Select the files that you want to appear in the merge file."
                multiple
                @change="updateHeaders()"
              />
            </v-list>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-list shaped>
              <v-combobox
                v-model="selectedFileHeaders"
                :items="allFileHeaders"
                label="Select the columns you want to appear in the merge file"
                multiple
              />
            </v-list>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              v-model="mergeFileDescription"
              label="Merge File Description"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-switch
              v-model="removeBlankAddresses"
              class="pl-2"
              inset
              :label="'Remove blank addresses? ' + formatBoolean (removeBlankAddresses)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-switch
              v-model="combineAddresses"
              class="pl-2"
              inset
              :label="'Combine address fields? ' + formatBoolean (combineAddresses)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-switch
              v-model="removeBlankNames"
              class="pl-2"
              inset
              :label="'Remove blank names? ' + formatBoolean (removeBlankNames)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-switch
              v-model="filterByZip"
              class="pl-2"
              inset
              :label="'Filter by default zips? ' + formatBoolean (filterByZip)"
            />
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="mergeFiles()"
          >
            Merge
          </v-btn>
          <v-btn
            color="primary"
            @click="showMergeDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showMatchbackDialog"
      persistent
      max-width="500"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Create Matchback Files
        </v-card-title>
        <v-row dense>
          <v-col>
            <v-list shaped>
              <v-combobox
                v-model="selectedMergeFile"
                :items="allFiles"
                item-text="description"
                item-value="id"
                label="Select the file to use to create the matchback files"
              />
            </v-list>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="createCampaignMatchback()"
          >
            Create Matchback Files
          </v-btn>
          <v-btn
            color="primary"
            @click="showMatchbackDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import MailCampaignService from '@/services/MailCampaignService'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'

  export default {
    name: 'MailCampaignFiles',
    components: {
      ErrorMessage,
    },
    filters: {
    },
    mixins: [formatterMixin, navigationMixin],
    data () {
      return {
        description: '',
        showErrorDialog: false,
        error: '',        
        valid: false,
        dialog: false,
        items: [],
        files: [],
        combineAddresses: true,
        removeBlankAddresses: true,
        removeBlankNames: true,
        filterByZip: true,
        search: '',
        loading: true,
        loadingMessage: 'Loading...',
        expanded: [],
        singleExpand: false,
        headers: [
          { text: 'ID', value: 'id' },
          { text: 'Filename', value: 'filename' },
          { text: 'Description', value: 'description' },
          { text: 'File Type', value: 'file_type' },
          { text: 'URL', value: 'url', align: 'center' },
          { text: 'Created', value: 'created_at', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        file: '',
        progress: 0,
        uploading: false,
        mailCampaignId: null,
        showMergeDialog: false,
        showMatchbackDialog: false,
        allFileHeaders: [],
        selectedFileHeaders: [],
        selectedFiles: [],
        dataFiles: [],
        allMergeFiles: [],
        selectedMergeFile: [],
        allFiles: [],
        campaign: null,
        mergeFileDescription: 'Merge File',
        manifestFile: false,
        manifestUrls: [],
        matchbackRequests: [],
        matchbackError: false,
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      ...mapGetters(['accountId']),
    },
    watch: {
      accountId () {
        this.loadFiles()
      },
    },
    created () {
      this.mailCampaignId = (this.$route.params.mail_campaign_id)
      this.loadFiles()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },      
      loadFiles: function () {
        this.items = []
        this.loading = true
        this.loadingMessage = 'Loading files...'
        MailCampaignService.getFiles(this.mailCampaignId)
          .then(response => {
            this.items = response.data.files
            this.allFiles = this.items.filter(item => item.file_type === 'data' || item.file_type === 'Merge' || item.file_type == "Manifest").map(f => (({ id, description, url, headers, file_type }) => ({ id, description, url, headers, file_type }))(f))
            this.dataFiles = this.items.filter(item => item.file_type === 'data').map(f => (({ id, description, url, headers }) => ({ id, description, url, headers }))(f))
            this.manifestUrls = this.items.filter(item => item.file_type === 'Manifest').map(f => (({ id, description, url }) => ({ id, description, url }))(f))
            this.selectedFiles = this.dataFiles
            this.allMergeFiles = this.items.filter(item => item.file_type === 'Merge').map(f => (({ id, description, url, headers }) => ({ id, description, url, headers }))(f))
            this.selectedMergeFile = this.allMergeFiles[0]
            this.campaign = response.data.campaign
            this.allFileHeaders = [].concat(...this.selectedFiles.map(h => h.headers)).filter((v, i, a) => a.indexOf(v) === i)
            this.selectedFileHeaders = this.allFileHeaders
            this.loading = false
          })
          .then(() => {
            this.loadMatchbackRequests()
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      loadMatchbackRequests: function () {
        this.matchbackRequests = []
        this.loading = true
        this.loadingMessage = 'Loading Matchback Requests'
        MailCampaignService.getMatchbackRequests(this.manifestUrls.map(m => m.url))
          .then(response => {
            this.matchbackRequests = response.data
            this.matchbackError = this.matchbackRequests.filter(item => item.error === true).length > 0
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },      
      updateHeaders () {
        this.allFileHeaders = [].concat(...this.selectedFiles.map(h => h.headers)).filter((v, i, a) => a.indexOf(v) === i)
        this.selectedFileHeaders = this.allFileHeaders
      },
      deleteItem (item) {
        const index = this.items.indexOf(item)
        if (confirm('Are you sure you want to delete this file?') && this.items.splice(index, 1)) {
          // Call the API to update.
          this.loading = true
          MailCampaignService.deleteFile(item.mail_campaign_id, item.id)
            .then(response => {
              this.loading = false
              this.loadFiles()
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
      handleFileUpload () {
        this.loading = true
        this.uploading = true
        this.progress = 1
        var numberFilesToUpload = this.file.length
        var numberFilesUploaded = 0
        for (const file of this.file) {
          var data = {
            file: file,
            mailCampaignId: this.mailCampaignId,
            description: this.description,
            manifestFile: this.manifestFile,
          }

          MailCampaignService.uploadFiles(data, (event) => {
          })
            .then((response) => {
              numberFilesUploaded += 1
              var progress = Math.round((100 * numberFilesUploaded) / numberFilesToUpload)
              this.progress = progress
              if (numberFilesUploaded === numberFilesToUpload) {
                this.progress = 0
                this.file = undefined
                this.uploading = false
                this.loadFiles()
              }
            })
            .catch(() => {
              this.progress = 0
              this.file = undefined
              this.uploading = false
            })
        }
      },
      mergeFiles () {
        this.showMergeDialog = false
        this.loading = true
        const data = {
          mailCampaignId: this.$route.params.mail_campaign_id,
          headers: this.selectedFileHeaders,
          combineAddresses: this.combineAddresses,
          removeBlankNames: this.removeBlankNames,
          removeBlankAddresses: this.removeBlankAddresses,
          filterByZip: this.filterByZip,
          fileIds: this.selectedFiles.map(f => f.id),
          mergeFileDescription: this.mergeFileDescription,
        }
        MailCampaignService.mergeFiles(data)
          .then(response => {
            this.loading = false
            this.loadFiles()
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      createCampaignMatchback () {
        this.showMatchbackDialog = false
        this.loading = true
        MailCampaignService.createCampaignMatchback((this.$route.params.mail_campaign_id), this.campaign.start_matchback_date, this.campaign.end_matchback_date, this.selectedMergeFile.url)
          .then(response => {
            this.loading = false
            this.loadFiles()
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      selectFile (file) {
        this.progress = 0
        this.file = file
      },
    },
  }
</script>
